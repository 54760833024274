<template>
  <Section id="section-5" class="cards-container-margin section-bg-white">
    <Heading
      class="header"
      :label="title || $t('pages.howitworks.sections.subHeaders[0].title')"
      tag="h2"
    />
    <SectionHeader
      :subtitle="
        subtitle || $t('pages.howitworks.sections.subHeaders[0].subtitle')
      "
    />
    <AftersalesServicesSwiper :slides="serviceCards" />
  </Section>
</template>

<script>
import {
  Heading,
  Section,
  SectionHeader,
  AftersalesServicesSwiper,
} from "@sc/ds-ui-component-library";

export default {
  name: "CpAftersalesServices",
  components: {
    Heading,
    Section,
    SectionHeader,
    AftersalesServicesSwiper,
  },
  props: {
    serviceCards: {
      type: Array,
    },
    title: {
      type: String,
    },
    subtitle: {
      type: String,
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
#section-5 {
  padding-top: 112px;
  --section-header-margin: 64px 0px;
  --diff-swiper-slide-max-width: 25%;
  --heading-color: var(--color-1);

  .section-header {
    text-align: center;
    margin: auto;
  }
}

.cards {
  display: flex;
  gap: 16px;
  --card-border-radius: 4px;
  --card-header-shadow: none;

  .card-content-inline {
    flex-direction: column;
    --card-content-align: center;
    text-align: center;
    --text-label-font-size: 16px;
    gap: 16px;
    padding: 40px 48px;
    --heading-medium-color: var(--theme-color);
  }
  .separator {
    height: 1px;
    width: 356px;
    background-color: var(--color-4);
    margin: auto;
  }
}

@media (max-width: 768px) {
  .cards {
    flex-direction: column;
    height: 100%;
    width: 100%;
  }
}

#section-5 {
  --section-bg-color: var(--color-7);

  .aftersalesservices-swapper {
    background-color: var(--color-7);

    ::v-deep .card {
      --card-background-color: var(--color-7);
    }
  }
}
</style>
