<template>
  <div class="page">
    <Section id="section-1" class="section-with-img">
      <div class="image-content">
        <SectionHeader :title="$t('pages.howitworks.textImageSection.title')" />
      </div>
      <img
        :src="
          $t('resources.images.howitworkspage.hero') ||
          require('@/assets/images/stuff/howitworks1.webp')
        "
        class="hero-image"
        :alt="$t('pages.howitworks.textImageSection.alt')"
      />
    </Section>

    <Section id="section-7" class="section-bg-white">
      <Heading
        class="header"
        :label="$t('pages.howitworks.sections.header.title')"
        tag="h2"
      />
      <SectionHeader
        :subtitle="$t('pages.howitworks.sections.header.subtitle')"
      />
    </Section>

    <div class="information-div">
      <template v-for="(info, idx) in informationPanelsSlice(0, 3)">
        <InformationPanel
          :key="`info-${idx}`"
          :title="info.title"
          :image="info.image"
          :information="info.information"
          :buttonLabel="info.buttonLabel"
          :reverse="info.reverse"
          :imageAlt="info.alt"
          @onClick="pageRouting(info.route)"
        />
      </template>
    </div>

    <Section id="section-8">
      <Heading
        class="header"
        :label="$t('pages.howitworks.ourVehicles')"
        tag="h2"
      />
      <div class="section-content-center">
        <Button
          :label="$t('pages.howitworks.buttons[0].label')"
          type="secondary"
          @onClick="onClickSearch"
        ></Button>
      </div>
    </Section>

    <template v-for="info in informationPanelsSlice(3, 5)">
      <InformationPanel
        :key="info"
        :title="info.title"
        :image="info.image"
        :information="info.information"
        :buttonLabel="info.buttonLabel"
        :reverse="info.reverse"
        @onClick="pageRouting(info.route)"
      />
    </template>

    <CpAftersalesServices :serviceCards="serviceCards" />
  </div>
</template>

<script>
import {
  Section,
  SectionHeader,
  Button,
  InformationPanel,
  Heading,
} from "@sc/ds-ui-component-library";

import { PAGES } from "../../../enums/pages";

import CpAftersalesServices from "../../refactor/CpAftersalesServices.vue";

export default {
  components: {
    Button,
    Section,
    SectionHeader,
    InformationPanel,
    Heading,
    // REFACTORED
    CpAftersalesServices,
  },
  metaInfo() {
    return {
      title: this.$t("meta.howItWorks.title"),
      meta: [
        { charset: "utf-8" },
        {
          vmid: "description",
          name: "description",
          content: this.$t("meta.howItWorks.description"),
        },
        {
          vmid: "keywords",
          name: "keywords",
          content: this.$t("meta.howItWorks.keywords"),
        },
        {
          vmid: "og:title",
          property: "og:title",
          content: this.$t("meta.howItWorks.title"),
        },
        {
          vmid: "og:description",
          property: "og:description",
          content: this.$t("meta.howItWorks.description"),
        },
        {
          vmid: "og:url",
          property: "og:url",
          content: window.location.href,
        },
        {
          vmid: "og:image",
          property: "og:image",
          content: this.$t("meta.howItWorks.image"),
        },
      ],
    };
  },
  methods: {
    pageRouting(route) {
      this.$router.push(`/${route}`);
    },
    onClickSearch() {
      this.$router.push({
        name: PAGES.SEARCH,
      });
    },
    informationPanelsSlice(start, end) {
      if (start > this.informationPanels.length) return [];
      return this.informationPanels.slice(start, end);
    },
  },
  computed: {
    serviceCards() {
      return [
        {
          image:
            this.$t("resources.images.howitworkspage.swiperCard1") ||
            require("@/assets/images/howitworks-page/Garantia.svg"),
          title: this.$t("pages.howitworks.ourdifference.0.title"),
          description: this.$t("pages.howitworks.ourdifference.0.description"),
        },
        {
          image:
            this.$t("resources.images.howitworkspage.swiperCard2") ||
            require("@/assets/images/howitworks-page/Satisfacao_Reembolso.svg"),
          title: this.$t("pages.howitworks.ourdifference.1.title"),
          description: this.$t("pages.howitworks.ourdifference.1.description"),
        },
      ];
    },
    //TODO para tornar a imagem alteravel pelo i18n
    informationPanels() {
      return [
        {
          title: this.$t("pages.howitworks.informationPanels.0.title"),
          information: this.$t(
            "pages.howitworks.informationPanels.0.information"
          ),
          image:
            this.$t("resources.images.howitworkspage.section1") ||
            require("@/assets/images/stuff/howitworks2.webp"),
          buttonLabel: this.$t(
            "pages.howitworks.informationPanels.0.buttonLabel"
          ),
          reverse: false,
          route: this.$t("pages.howitworks.informationPanels.0.route"),
          alt: this.$t("pages.howitworks.informationPanels.0.alt"),
        },
        {
          title: this.$t("pages.howitworks.informationPanels.1.title"),
          information: this.$t(
            "pages.howitworks.informationPanels.1.information"
          ),
          image:
            this.$t("resources.images.howitworkspage.section2") ||
            require("@/assets/images/stuff/howitworks3.webp"),
          buttonLabel: this.$t(
            "pages.howitworks.informationPanels.1.buttonLabel"
          ),
          reverse: true,
          route: this.$t("pages.howitworks.informationPanels.1.route"),
          alt: this.$t("pages.howitworks.informationPanels.1.alt"),
        },
        {
          title: this.$t("pages.howitworks.informationPanels.2.title"),
          information: this.$t(
            "pages.howitworks.informationPanels.2.information"
          ),
          image:
            this.$t("resources.images.howitworkspage.section3") ||
            require("@/assets/images/stuff/howitworks4.webp"),
          buttonLabel: this.$t(
            "pages.howitworks.informationPanels.2.buttonLabel"
          ),
          reverse: false,
          route: this.$t("pages.howitworks.informationPanels.2.route"),
          alt: this.$t("pages.howitworks.informationPanels.2.alt"),
        },
      ];
    },
  },
  mounted() {
    this.$meta().refresh();
  },
};
</script>

<style lang="scss" scoped>
.page {
  background-color: var(--color-7);
  --section-padding: 4rem;
  .section-header {
    text-align: center;
    margin: auto;
  }
}

.information-wrapper {
  padding: 56px 11.198%;
  background-color: var(--color-7);

  ::v-deep .image {
    --title-color: var(--theme-color);
  }

  ::v-deep .information {
    --heading-color: var(--color-1);
  }
}

#section-7 {
  --section-padding: 80px 0px;
  --heading-color: var(--color-1);
  --text-label-font-size: 21px;
  --text-label-line-height: 27px;
  --text-label-weight: 600;
  --text-label-color: var(--color-1);
  --section-header-width: 40%;
  text-align: center;

  ::v-deep .header {
    margin-bottom: 16px;
  }

  ::v-deep .section-header {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}

#section-8 {
  --section-padding: 80px 0px 80px 0px;
  --heading-color: var(--color-1);
  text-align: center;
}

.section-content-center {
  display: flex;
  align-items: center;
  flex-direction: column;

  & > Button {
    margin-top: 40px;
  }
}

.section-bg-white {
  --section-bg-color: var(--color-7);
}

.section-with-img {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  --section-padding: 0px;
  position: relative;

  .section-header {
    flex: 1;
    margin: 40px 0px;
    --title-color: var(--color-1);
  }

  .image-content {
    margin: auto;
    // background-color: var(--color-3);
    & > img {
      //width: 756px;
    }
    z-index: 2;
  }
}

#section-5 {
  text-align: center;

  ::v-deep .header {
    margin-bottom: 16px;
  }

  ::v-deep .card {
    --heading-medium-font-weight: bold;
    --heading-medium-font-size: 28px;
    --heading-medium-line-height: 36px;

    --text-label-font-weight: 600;
    --text-label-font-size: 18px;
    --text-label-line-height: 23px;
  }

  ::v-deep .section-header {
    --text-label-font-size: 21px;
    --text-label-line-height: 27px;
    --text-label-weight: 600;
    --text-label-color: var(--color-1);
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}

.hero-image {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  object-fit: cover;
  z-index: 1;
}

#section-1 {
  min-height: 574px;
  --section-bg-color: none;

  ::v-deep .section-header {
    --title-color: var(--theme-color);
    .title {
      width: 95%;
      margin: auto;
      text-align: left;
      --title-font-size: 56px;
      --title-line-height: 73px;
    }
  }
}

::v-deep .section-header {
  --text-label-font-size: 21px;
  --text-label-line-height: 27px;
}

::v-deep .information-panel {
  .image {
    --title-medium-font-size: 28px;
    --title-medium-line-height: 36px;
  }
  .information {
    --heading-small-font-size: 18px;
    --heading-small-line-height: 23px;
    --text-label-small-font-weight: 600;
    --text-label-small-font-size: 16px;
    --text-label-small-line-height: 21px;
    --text-label-color: var(--color-1);
  }
}

::v-deep {
  .aftersalesservices-swapper {
    margin-top: 80px;
  }
}

::v-deep {
  .information-panel {
    .image {
      img {
        object-fit: cover;
      }
    }
  }
}

@media (max-width: 1920px) {
}

@media (max-width: 1680px) {
  #section-1 {
    min-height: 534px;

    --section-padding: 80px 52px;
    ::v-deep .section-header .title {
      --title-font-size: 48px;
      --title-line-height: 59px;
    }
  }
  #section-7 {
    --section-padding: 60px 52px;
    --heading-large-font-size: 38px;
    --heading-large-line-height: 46px;

    ::v-deep .section-header {
      --text-label-font-size: 20px;
      --text-label-line-height: 25px;
    }
  }
}

@media (max-width: 1440px) {
  ::v-deep {
    .information-wrapper {
      .information-panel {
        flex-direction: column;

        &.reverse {
          flex-direction: column;
        }
      }
    }
  }
  #section-1 {
    min-height: 534px;

    --section-padding: 80px 52px;
    ::v-deep .section-header .title {
      --title-font-size: 38px;
      --title-line-height: 49px;
    }
  }

  #section-7 {
    --section-padding: 60px 52px;
    --heading-large-font-size: 28px;
    --heading-large-line-height: 36px;

    ::v-deep .section-header {
      --text-label-font-size: 18px;
      --text-label-line-height: 27px;
    }
  }

  #section-8 {
    margin-top: 60px;
    --section-padding: 52px;
    --heading-large-font-size: 28px;
    --heading-large-line-height: 36px;
  }

  ::v-deep .information-wrapper {
    padding: 8px 52px;
    --information-panel-flex-wrap: no-wrap;
    .image {
      --title-medium-font-size: 24px;
      --title-medium-line-heigth: 21px;
    }
    .information {
      margin: 0;
    }

    .information-panel {
      flex-direction: row;
      --information-panel-image-width: 650px;
      --information-panel-image-height: 335px;
      &.reverse {
        flex-direction: row-reverse;
      }
    }

    .information-blocks {
      --heading-small-font-size: 18px;
      --heading-small-line-heigth: 18px;
      --text-label-small-font-size: 16px;
      --text-label-small-line-heigth: 16px;
    }
  }

  #section-5 {
    padding-top: 60px;
    --section-padding: 52px;
    --heading-large-font-size: 28px;
    --heading-large-line-height: 36px;

    ::v-deep .section-header {
      --text-label-font-size: 18px;
      --text-label-line-height: 27px;
    }

    ::v-deep .aftersalesservices-swapper {
      margin-top: 18px;
      .card {
        --heading-medium-font-size: 21px;
        --heading-medium-line-height: 21px;
        --text-label-font-size: 16px;
        --text-label-line-height: 20px;
      }
    }
  }
}
@media (max-width: 1366px) {
  #section-1 {
    min-height: 494px;

    --section-padding: 80px 40px;
    ::v-deep .section-header .title {
      --title-font-size: 38px;
      --title-line-height: 49px;
    }
  }

  #section-7 {
    --section-padding: 60px 40px;
    --heading-large-font-size: 28px;
    --heading-large-line-height: 36px;

    ::v-deep .section-header {
      --text-label-font-size: 18px;
      --text-label-line-height: 27px;
    }
  }

  #section-8 {
    margin-top: 60px;
    --section-padding: 40px;
    --heading-large-font-size: 28px;
    --heading-large-line-height: 36px;
  }

  ::v-deep .information-wrapper {
    padding: 8px 40px;
    --information-panel-flex-wrap: no-wrap;
    .image {
      --title-medium-font-size: 24px;
      --title-medium-line-heigth: 21px;
    }
    .information {
      margin: 0;
    }

    .information-panel {
      flex-direction: row;
      --information-panel-image-width: 620px;
      --information-panel-image-height: 320px;
      &.reverse {
        flex-direction: row-reverse;
      }
    }

    .information-blocks {
      --heading-small-font-size: 18px;
      --heading-small-line-heigth: 18px;
      --text-label-small-font-size: 16px;
      --text-label-small-line-heigth: 16px;
    }
  }

  #section-5 {
    padding-top: 60px;
    --section-padding: 40px;
    --heading-large-font-size: 28px;
    --heading-large-line-height: 36px;

    ::v-deep .section-header {
      --text-label-font-size: 18px;
      --text-label-line-height: 27px;
    }

    ::v-deep .aftersalesservices-swapper {
      margin-top: 18px;
      .card {
        --heading-medium-font-size: 21px;
        --heading-medium-line-height: 21px;
        --text-label-font-size: 16px;
        --text-label-line-height: 20px;
      }
    }
  }
}
@media (max-width: 1280px) {
  #section-1 {
    min-height: 474px;

    --section-padding: 80px 28px;
    ::v-deep .section-header .title {
      --title-font-size: 38px;
      --title-line-height: 49px;
    }
  }

  #section-7 {
    --section-padding: 60px 28px;
    --heading-large-font-size: 28px;
    --heading-large-line-height: 36px;

    ::v-deep .section-header {
      --text-label-font-size: 18px;
      --text-label-line-height: 27px;
    }
  }

  #section-8 {
    margin-top: 60px;
    --section-padding: 28px;
    --heading-large-font-size: 28px;
    --heading-large-line-height: 36px;
  }

  ::v-deep .information-wrapper {
    padding: 8px 28px;
    --information-panel-flex-wrap: no-wrap;
    .image {
      --title-medium-font-size: 24px;
      --title-medium-line-heigth: 21px;
    }
    .information {
      margin: 0;
    }

    .information-panel {
      flex-direction: row;
      --information-panel-image-width: 582px;
      --information-panel-image-height: 300px;
      &.reverse {
        flex-direction: row-reverse;
      }
    }

    .information-blocks {
      --heading-small-font-size: 18px;
      --heading-small-line-heigth: 18px;
      --text-label-small-font-size: 16px;
      --text-label-small-line-heigth: 16px;
    }
  }

  #section-5 {
    padding-top: 60px;
    --section-padding: 28px;
    --heading-large-font-size: 28px;
    --heading-large-line-height: 36px;

    ::v-deep .section-header {
      --text-label-font-size: 18px;
      --text-label-line-height: 27px;
    }

    ::v-deep .aftersalesservices-swapper {
      margin-top: 18px;
      .card {
        --heading-medium-font-size: 21px;
        --heading-medium-line-height: 21px;
        --text-label-font-size: 16px;
        --text-label-line-height: 20px;
      }
    }
  }
}
@media (max-width: 1024px) {
  #section-1 {
    --section-padding: 60px 20px;
  }

  ::v-deep {
    .aftersalesservices-swapper {
      margin-top: 20px;
    }
  }
  #section-7 {
    --section-header-width: 90%;
  }
  #section-5 {
    ::v-deep .section-header {
      --section-header-width: 100%;
    }
  }
  #section-1 {
    --section-padding: 60px 18px;
    min-height: unset;
    ::v-deep .section-header {
      --title-color: var(--theme-color);
      .title {
        width: 100%;
        padding-left: 16px;
        padding-right: 16px;
        margin: auto;
        text-align: left;
      }
    }
  }

  ::v-deep .information-wrapper {
    --information-panel-flex-wrap: wrap;
    .information-panel {
      flex-direction: column;
      --information-panel-image-width: 100%;
      --information-panel-image-height: auto;
      &.reverse {
        flex-direction: column;
      }
    }
  }
  ::v-deep #section-5 {
    --aftersalesservices-swiper-slide-justify: center;
  }
}

@media (max-width: 768px) {
  ::v-deep .title {
    --title-font-size: var(--title-3-font-size);
    --title-line-height: var(--title-3-line-height);
    --title-weight: var(--font-weight-bold);
  }

  .page {
    --section-header-width: 100%;
  }

  #section-1 {
    ::v-deep .section-header {
      .title {
        --title-font-size: var(--title-3-font-size);
        --title-line-height: var(--title-3-line-height);
        --title-weight: var(--font-weight-bold);
      }
    }
  }

  ::v-deep {
    .information-wrapper {
      padding: 40px 16px;

      .image {
        img {
          --information-panel-image-height: auto;
        }
      }

      .information {
        --information-panel-information-margin: 0px;
        .information-button {
          .btn {
            margin: auto;
            width: 100%;
            --button-justify-content: center;
          }
        }
      }
    }
  }

  #section-1,
  #section-7,
  #section-8,
  #section-5 {
    --section-header-width: 100%;

    ::v-deep {
      .btn {
        width: 100%;
        --button-justify-content: center;
      }
    }
  }

  #section-1 {
    --section-padding: 60px 20px;
    min-height: unset;
  }

  #section-7 {
    --section-padding: 40px 16px 40px 16px;
  }

  #section-8 {
    --section-padding: 60px 16px 60px 16px;
  }

  #section-5 {
    --section-padding: 80px 16px 0px 16px;
    --section-margin: 0px 0px;
    ::v-deep {
      --aftersalesservices-swiper-slide-justify: left;
    }
  }
}
</style>
